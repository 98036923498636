/*eslint-disable */
<template>
  <b-container fluid class="main-box ">
    <b-row>
      <b-col lg="12" class="p-0">
        <template v-if="!propOpenedInModal">
          <div class="form-row row m-0">
            <div class="col-12 col-xs-9 col-sm-10 col-md-11 mb-3 lg-10 pl-1 pr-1 pb-0">
              <p class="chatbox"><span id="messageText" class="textarea" style="height: auto;" role="textbox" contenteditable></span></p>
            </div>
            <div class="col-12 col-xs-3 col-sm-2 col-md-1 mb-3 lg-2 pl-0 buttonbox">
              <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                <b-spinner label="Spinning"></b-spinner>
              </button>
              <button type="button" class="btn btn-primary float-right" @click="messageAdd()">
                {{ cvSubmitBtn }}
              </button>
            </div>
          </div>
        </template>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  line-height: 20px;
  height: 250px !important;
  overflow-y: auto;
  padding-right: 10px;
  border: 0px;
}
.buttonbox{
  position: relative;
  button{
    position: absolute;
    bottom: 0px;
  }
}

.textarea[contenteditable]:empty::before {
  content: "Type message here";
  color: gray;
}
.chatbox{
    border: 1px solid var(--iq-light-info);
    border-radius: 10px;
    padding: 7px 0px 4px 15px;
    margin-bottom: 0px;
    background: #fff;
}
.textarea:focus-visible{
  outline: 0px;
}
.main-box{
  padding: 10px 15px 0px 10px;
  border: 1px solid var(--iq-border-light);
  padding-bottom: 0px;
  margin-bottom: 0px;
  background-color: var(--iq-border-light);
}
@media (max-width: 576px){
.buttonbox{
    height: 28px;
    margin-left: 8px;
}
}

</style>
<script>
import { Messages } from "../../../FackApi/api/message"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "MessageAdd",
  props: {
    propAdm: {
      type: String
    },
    propModuleType: {
      type: String
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add message",
      cvCardSubHeader: "Add message ",
      cvSubmitBtn: "Send",
      cvModuleObjIdLabel: "module obj id",
      cvModueTypeLabel: "modue type",
      cvMessageLabel: "Send Message for query",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Message",
      vmMessageFormData: Object.assign({}, this.initFormData()),
      vmMessageDesc: null,
      vmMessageType: null
    }
  },
  mounted () {
    this.MessageView()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "module_obj_id": "",
        "modue_type": "",
        "message": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmMessageFormData) {
          if (!this.vmMessageFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
    * postView
    */
    async MessageView () {
      try {
        this.cvLoadingview = true
        this.vmMessageFormData.module_obj_id = this.propAdm
        this.vmMessageFormData.modue_type = this.propModuleType
      }
      catch (err) {
        console.error("Exception occurred at postView() and Exception:", err)
      }
      finally {
        this.cvLoadingview = false
      }
    },
    /**
     * messageAdd
     */
    async messageAdd () {
      this.vmMessageFormData.message = document.getElementById("messageText").innerText
      if (!this.validate()) {
        return false
      }

      try {
        this.cvLoadingStatus = true
        let messageAddResp = await Messages.messageAdd(this, this.vmMessageFormData)
        await ApiResponse.responseMessageDisplay(this, messageAddResp)
        if (messageAddResp && !messageAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseMessageAddModal", messageAddResp.resp_data)
        }

        this.$emit("emitMessageListUpdate")
        document.getElementById("messageText").innerText = ""
      }
      catch (err) {
        console.error("Exception occurred at messageAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>

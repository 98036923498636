/*eslint-disable */
<template>
  <div id="reviewAdmission">
    <div class="iq-card showFlexListDiv pb-1">
      <div class="profile-header">
        <div class="cover-container">
          <img v-if="orgObj.cover_pic" :src="orgObj.cover_pic.image_medium" class="rounded img-fluid">
          <img v-else :src="vmBannerImage" class="rounded img-fluid">
        </div>
        <div class="user-detail text-center mb-3">
          <div class="profile-img">
            <img v-if="orgObj.profile_pic" :src="orgObj.profile_pic.image_medium" alt="profile-img" class="img-fluid rounded-circle orgLogo">
            <b-avatar v-else variant="primary" class="avatar-100 rounded" :text="getFirstLetterOfAString(orgObj.org_name)" style="border-radius: 100%!important;">
            </b-avatar>
          </div>
          <div class="profile-detail main_heading">
            <h3 class="pt-3">
              {{orgObj.org_name}} Application Form
            </h3>
          </div>
        </div>
      </div>

      <!-- Tabs visible in student view only -->
      <div class="user-tabing showFlexList" v-if="!orgView && !adminView && !viewApplicationRoute">
        <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
          <div  class="flex-fill p-0" @click="selectedTab = cvTabObj.admission">
            <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.admission" id="pills-feed-tab" href="#profile-feed"
              ariaControls="pills-home" role="tab" :ariaSelected="selectedTab === cvTabObj.admission" :title="cvTabObj.admission" />
          </div>

          <div  class="flex-fill p-0" @click="selectedTab = cvTabObj.personalDetail">
            <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.personalDetail" id="pills-personalDetail-tab" href="#pills-personalDetail-tab"
              ariaControls="pills-personalDetail-tab" role="tab" :ariaSelected="selectedTab === cvTabObj.personalDetail" :title="cvTabObj.personalDetail" />
          </div>

          <div  class="flex-fill p-0"  @click="selectedTab = cvTabObj.education">
            <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.education" id="pills-activity-tab" href="#profile-activity"
              ariaControls="pills-profile" role="tab" :ariaSelected="selectedTab === cvTabObj.education" :title="cvTabObj.education" />
          </div>

          <div  class="flex-fill p-0" @click="selectedTab = cvTabObj.program">
            <tab-nav-items  class="flex-fill p-0" :active="selectedTab === cvTabObj.program" id="pills-photo-tab"  href="#photoActive"
              ariaControls="pills-photo" role="tab" :ariaSelected="selectedTab === cvTabObj.program" :title="cvTabObj.program" />
          </div>

          <div class="flex-fill p-0" @click="selectedTab = cvTabObj.attachments">
            <tab-nav-items  class="flex-fill p-0"  :active="selectedTab === cvTabObj.attachments" id="pills-attachments-tab"  href="#pills-attachments"
              ariaControls="pills-attachments" role="tab" :ariaSelected="selectedTab === cvTabObj.attachments" :title="cvTabObj.attachments" />
          </div>
          <div class="flex-fill p-0" @click="selectedTab = cvTabObj.review">
            <tab-nav-items  class="flex-fill p-0"  :active="selectedTab === cvTabObj.review" id="pills-review-tab"  href="#pills-review"
              ariaControls="pills-review" role="tab" :ariaSelected="selectedTab === cvTabObj.review" :title="cvTabObj.review" />
          </div>
        </tab-nav>
      </div>
      <!-- Tabs visible in student view only -->

      <b-container fluid class="p-0 pt-2">
        <b-row class="m-0 Documents admission_personal_info">
          <b-col md="12" class="review_form_spacing" v-if="selectedTab === cvTabObj.admission || selectedTab === cvTabObj.review">
            <!-- This is Review Form Edit Mode -->
            <div v-if="!viewApplicationRoute">
              <ValidationObserver ref="admissionForm">
                <form class="mt-0" @submit.prevent="onSubmit">
                  <div class="form-row">
                    <div class="col-md-12 mb-3">
                      <label for="validation_user_name">Name</label>
                      <span v-if="userData.user_name">:&nbsp;{{ userData.user_name }}</span>
                    </div>

                    <div class="col-md-12 mb-3" v-if="vmAdmissionForm.cour_degree_name && !vmAdmissionForm.cour_degree_name == ''">
                      <label for="validation_degree"> Degree / Diploma</label>
                      <select
                          v-model="vmAdmissionForm.cour_degree_name"
                          type="text"
                          :disabled="disableUserInput"
                          class="form-control"
                          id="validation_degree">
                        <option v-for="(degree, index) of orgDegreeList" :key="index+'degree'" :value="degree.cour_degree_name">{{degree.cour_degree_name}}</option>
                      </select>
                    </div>

                    <div class="col-md-12 mb-3">
                      <label for="validation_course">Course / Program / Specialization</label>
                      <select
                          v-model="vmAdmissionForm.cour_name"
                          type="text"
                          :disabled="disableUserInput"
                          class="form-control"
                          @change="courSelect"
                          id="validation_course">
                        <option v-for="(course, index) of orgCourseList" :key="index+'course'" :value="course.cour_name">{{course.cour_name}}</option>
                      </select>
                    </div>

                    <div class="col-md-12 mb-3">
                      <label for="validation_batch">Admission Cycle</label>
                      <select v-model="vmAdmissionForm.batch" class="form-control" :disabled="disableUserInput">
                        <option v-for="(courCycle, cIndex) of orgCourAdmCycleList" :key="cIndex+'courCycle'">{{courCycle}}-{{getYear(courCycle)}}</option>
                      </select>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <!-- This is Review Form Edit Mode -->

            <!-- This is Review Form View Mode -->
            <div class="form_spacing" v-else>
              <div>
                <h4>Admission Details
                </h4>
                <hr class="w-100 theme_color"/>
                <div class="col-md-12 mb-3">
                  <label for="validation_degree">Degree / Diploma:</label>&nbsp;
                  <label>{{vmAdmissionForm.cour_degree_name ? vmAdmissionForm.cour_degree_name : ""}}</label>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_degree">Specialization:</label>&nbsp;
                  <label>{{vmAdmissionForm.cour_name ? vmAdmissionForm.cour_name.replace(vmAdmissionForm.cour_degree_name+" - ", "") : "-"}}</label>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_batch">Admission Cycle:</label>&nbsp;
                  <label>{{vmAdmissionForm.batch}}</label>
                </div>
              </div>
            </div>
            <!-- This is Review Form View Mode -->
          </b-col>

          <!--If this is not edit or add page only then show  -->
          <b-col md="12" class="review_form_spacing" v-if="(selectedTab === cvTabObj.personalDetail || selectedTab === cvTabObj.review)">
            <div class="mt-3 table_scroll">
              <h4>Personal Information</h4>
              <hr class="w-100 theme_color"/>
              <PersonalInfo v-if="studentData && Object.keys(studentData).length" :propUserId="studentData.user_id" :propUserData="studentData" :propModuleType="'ADM'"/>
            </div>
          </b-col>

          <b-col md="12" class="review_form_spacing" :key="admissionObj.adm_id" v-if="(selectedTab === cvTabObj.education || selectedTab === cvTabObj.review)">
            <h4>Education
              <span class="educationAddIcon" v-if="studentView && (!admissionObj.adm_id || editMode)" @click="showAddEducation()"><i class="fa-solid fa-circle-plus"></i></span>
            </h4>
            <hr class="w-100 theme_color"/>
            <div class="mt-3" v-if="user_profile.education && user_profile.education.length">
              <Education :propUserView="editMode" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" :propAdmId="admissionObj.adm_id" @emitUserProfileEducation="addEducation">
              </Education>
            </div>
          </b-col>

          <b-col md="12" class="review_form_spacing" v-if="(selectedTab === cvTabObj.program || selectedTab === cvTabObj.review)">
              <h4>Programs & Tests
                <span class="educationAddIcon" v-if="studentView && (!admissionObj.adm_id || editMode)" @click="showProgramModal()"><i class="fa-solid fa-circle-plus"></i></span>
              </h4>
              <hr class="w-100 theme_color"/>
              <div class="row-100" v-for="(item, index) in userProgramTest" :key="index+'program'" >
                <ProgramTest :propUserView="editMode" :propProgramDetail="item" :propOpenedInModal="false" @emitCloseProgramModal="closeProgramModal">
                </ProgramTest>
              </div>
          </b-col>
        </b-row>
      </b-container>

      <template v-if="addEducationShow">
        <Education :propEducationForm="educationForm" :propEducationAddShow='addEducationShow' @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="addEducation"></Education>
      </template>

      <ProgramTest v-if="showProgramTestModal" :propProgramDetail="programEditObj" :propOpenedInModal="showProgramTestModal" @emitCloseProgramModal="closeProgramModal">
      </ProgramTest>

      <b-container fluid class="p-0">
       <b-row class="m-0">
        <b-col md="12" class="review_form_spacing" v-if="(selectedTab === cvTabObj.review || selectedTab === cvTabObj.attachments)">
          <h4>Documents</h4>
          <hr class="theme_color"/>
          <div class="pl-2">
            <table class="mt-3 w-100 table_scroll">
              <tr>
                <th>
                  <h6>School</h6>
                </th>
                <th>
                  <h6>Class</h6>
                </th>
                <th>
                  <h6>Document Name</h6>
                </th>
                <th>
                <h6>Document Type</h6>
                </th>
                <th>
                <h6>Document</h6>
                </th>
              </tr>
              <template v-for="(education, Pindex) of user_profile.education">
                <template v-for="(attachment, index) of education.attachments">
                  <tr :key="index+'image'+Pindex">
                    <td>
                      <h6 class="educationTitle">{{education.institute_name}}
                      </h6>
                    </td>
                    <td>
                      <h6 class="educationTitle">{{education.education.class_name}}
                      </h6>
                    </td>
                    <td>
                      <h6 class="educationTitle">{{attachment.doc_name}}
                      </h6>
                    </td>
                    <td>
                      <h6 class="educationTitle">{{attachment.doc_type ? attachment.doc_type : cvAttachmentTagListObj.OtherDocuments}}
                      </h6>
                    </td>
                    <td class="attachmentCol mt-2 pointer">
                      <img v-if="attachment.content && attachment.content.includes('application') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" alt="not found" @click="openFile(attachment.url)">
                      <img v-if="attachment.content && attachment.content.includes('image') || attachment.type && attachment.type.includes('image')" :src="attachment.url" alt="not found" @click="openFile(attachment.url)">
                    </td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
        </b-col>

        <b-col md="12" class="review_form_spacing" v-if="selectedTab === cvTabObj.review && (orgView || adminView)">
            <h4 >Application Review & Action</h4>
            <hr class="theme_color"/>
            <template>
              <div class="row mb-3 ml-0 w-100">
                <div class="col-4 pl-0 ml-0">
                  <label class="w-100" for="validation_reason">Reason</label>
                </div>
                <div class="col-8 pl-0 ml-0" >
                  <label class="w-100" for="validation_reason">Remarks</label>
                </div>
              </div>
              <div class="row mb-3 ml-0 w-100">
                <div class="col-4 pl-0 ml-0" >
                  <select class="form-control" v-model="admissionObj.adm_reason_code" id="validation_reason">
                    <option v-for="(reason, index) of cvAdmissionReasonOptions" :key="index+'reason'" :value="reason">
                      {{reason}}
                    </option>
                  </select>
                </div>
                <div class="col-8">
                  <textarea
                    rows="1"
                    v-model="admissionObj.adm_message"
                    type="textarea"
                    class="form-control"
                    id="validation_reason"
                    autogrow></textarea>
                </div>
              </div>
            </template>
            <!-- <button @click="downloadPage()">Download</button> -->
        </b-col>
      </b-row>
      </b-container>

      <div class="footer app_form__admin_three_button mt-2 mb-2 row w-100 ">
        <!-- Admin/Org View Footer Buttons -->
        <div class="col-sm-12 col-md-5 col-lg-6 col-12 mt-2 ml-0 w-100">
          <div class="col-12 pt-1 pl-0 ml-0">
            <label class="w-100" for="validation_reason">Current Status:
              <b-badge class="text-wrap w-125px" :style="getAdmissionStateColorCode(admissionObj.adm_state)">
                {{admissionStateInfo[admissionObj.adm_state]}}
              </b-badge>
          </label>
          </div>
        </div>

        <div class="col-sm-12 col-md-7 col-lg-6 col-12 mt-2 mb-3 accept_reject_more pr-0" v-if="admissionObj.adm_state && admissionObj.adm_state !== 'UNIV_APP_OFFER_SENT' && (orgView || adminView)">
          <!-- Here States like STU_APP_REJECTED, GADMIN_APP_NEED_INFO, UNIV_APP_ACCEPTED are only being used for color codes -->
          <b-button v-if="adminView  || (orgView && admissionObj.adm_state.includes('UNIV'))" :style="getAdmissionStateColorCode('STU_APP_REJECTED')" @click="applicationUpdate('REJECT')" class="mr-3">
            Reject
          </b-button>
          <b-button v-if="(adminView && (!admissionObj.adm_state.includes('UNIV'))) || (orgView && admissionObj.adm_state.includes('UNIV'))" :style="getAdmissionStateColorCode('GADMIN_APP_NEED_INFO')" @click="addUpdateAdmission('NEED_INFO')" class="mr-3">
            <!-- This is for admin need info, org need info before accept and after accept application -->
            Need More Information
          </b-button>
          <b-button v-if="(adminView && !admissionObj.adm_state.includes('UNIV')) || (orgView && admissionObj.adm_state.includes('UNIV') && admissionObj.adm_state_num < config.AdmissionStateNum.UNIV_APP_ACCEPTED)" :style="getAdmissionStateColorCode('UNIV_APP_ACCEPTED')" @click="applicationUpdate('ACCEPT')" class="mr-0">
            Accept
          </b-button>
          <b-button v-if="orgView && admissionObj.adm_state_num >= config.AdmissionStateNum.UNIV_APP_ACCEPTED" :style="getAdmissionStateColorCode('UNIV_APP_OFFER_SENT')" @click="applicationUpdate('OFFER')" class="ml-3">
            Send Offer
          </b-button>
        </div>
        <!-- Admin/Org View Footer Buttons -->

        <!-- Student View Buttons -->
        <div class="col-6 mt-1 pt-1 submit_sudent_form pr-0" v-if="studentView && ( !admissionObj.adm_state || admissionObj.adm_state && userProfileUnlockState[admissionObj.adm_state] )">
          <b-button v-if="admissionObj && admissionObj.spcm_id" size="sm" variant="primary" class="pull-right primary mb-3" @click="addUpdateAdmission()">
            {{ selectedTab === cvTabObj.review ? 'Submit Application' : 'Save and Next'}}
          </b-button>

          <b-button v-else size="sm" variant="primary" class="pull-right primary mb-3" @click="addUpdateAdmission()">
            {{selectedTab === cvTabObj.review && limitAvailabilityObj ? (limitAvailabilityObj.curr_cap_value > 0 ? 'Submit Application' : `Make Payment`) : 'Save and Next'}}
          </b-button>
        </div><!-- Admin/Org View Footer Buttons -->

        <!-- Only for Debugging -->
        <!-- <div class="warning-color"> {{ selectedTab }} {{ limitAvailabilityObj }}</div> -->
        <!-- Only for Debugging -->
      </div>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg"></div>
      </b-toast>

      <div class="review_form_spacing" v-if="selectedTab === cvTabObj.review">
        <MessageList v-if="admissionId" :propOpenedInModal="true" :propAdm="admissionId"></MessageList>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-header {
  margin-bottom: 0;
  padding: 0;
  .cover-container {
    overflow: hidden;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  .user-detail {
    position: relative !important;
    height: 40px;
    .profile-img {
      position: relative;
      top: 35px;
      img, .b-avatar {
        width: 84px;
        height: 84px;
        object-fit: contain;
      }
    }
  }
}
h4 {
  width: 100%;
  .educationAddIcon {
    float: right;
    color: var(--iq-primary);;
  }
}
.footer {
  padding-bottom: 29px;
  button {
    // float: ce;
  }
}
.attachmentCol {
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border: 2px solid aliceblue;
    margin-bottom: 6px;
  }
}
</style>
<script>
import { socialvue } from "../config/pluginInit.js"
import { Admissions } from "../FackApi/api/admission.js"
import { Images } from "../FackApi/api/image.js"
import { Organisations } from "../FackApi/api/organisation.js"
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"
import config from "../Utils/config.js"
import Utility from "../Utils/utility.js"
import Education from "./UserProfile/Education.vue"
import ProgramTest from "./UserProfile/ProgramTest.vue"
import pdfLogo from "/src/assets_gide/img/logo/pdfLogo.png"
import moment from "moment"
import MessageList from "../views/Gide/Message/MessageList.vue"
import PersonalInfo from "./UserProfile/PersonalInfo.vue"
import { Subscriptions } from "../FackApi/api/Subscription/subscription.js"

export default {
  name: "Admission",
  components: {
    Education,
    ProgramTest,
    MessageList,
    PersonalInfo
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleObjId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      vmAdmissionForm: {
        user_name: ""
      },
      showSCModal: true,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Admission Response",
      admissionObj: {
        adm_state: "STU_ADM_START"
      },
      editMode: false,
      user_profile: {},
      extraCurricularListObj: {},
      userProgramTest: {},
      cvTabObj: {
        admission: "Admission Details",
        education: "Education Details",
        program: "Entrance Exam and Test",
        personalDetail: "Personal Details",
        review: "Review",
        payment: "Payment",
        attachments: "Documents"
      },
      selectedTab: "Admission Details",
      orgObj: {},
      admissionId: null,
      universityId: null,
      vmBannerImage: Utility.getBgImageForLetter(this, "U"),
      adminView: false,
      studentView: false,
      orgView: false,
      addEducationShow: false,
      showProgramTestModal: false,
      admissionStateInfo: config.AdmissionStateInfo,
      showUnivAcceptRejectState: {
        UNIV_APP_NEED_INFO: "UNIV_APP_NEED_INFO",
        UNIV_APP_RECVD: "UNIV_APP_RECVD",
        UNIV_STU_APP_RESUBMIT: "Application resubmitted to University"
      },
      showAdminAcceptRejectState: {
        STU_APP_DONE: "STU_APP_DONE",
        GADMIN_APP_NEED_INFO: "GADMIN_APP_NEED_INFO",
        GADMIN_STU_APP_RESUBMIT: "Application resubmitted to GIDE Admin"
      },
      userProfileUnlockState: config.UserProfileUnlockState,
      disableUserInput: false,
      cvAttachmentTagListObj: config.AttachmentTagListObj,
      cvAdmissionReasonOptions: config.AdmissionNeedInfoReasons,
      orgDegreeList: [],
      orgCourseList: [],
      orgCourAdmCycleList: [],
      pdfLogo: pdfLogo,
      viewApplicationRoute: false,
      doubleConfirMessage: "By clicking submit you acknowledge that all the information is true to your knowledge. Are you sure you want to submit your application ?",
      doubleConfirMessageByAdmin: "Are you sure you want to change the status ?",
      config: config,
      studentData: {},
      modName: "admission", // Mapped to the Table modules. Used to chheck if this module is used in subscription or not
      limitAvailabilityObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()

    if (this.$route.query.state) {
      this.selectedTab = this.$route.query.state
    }

    if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
      // Gide Admin or Gide Counsellor
      this.adminView = true
      this.disableUserInput = true
    }
    else if (this.userData.user_role === "USERROLE11114") {
      // Student
      this.studentView = true
    }
    else if (this.userData.user_role === "USERROLE11115") {
      // University Admin
      this.orgView = true
      this.disableUserInput = true
    }

    this.admissionId = this.$route.params.admId
    this.universityId = this.$route.params.orgId

    if (this.admissionId && typeof this.admissionId === "string") {
      this.vmAdmissionForm.country_name = this.userData.country_name
      this.vmAdmissionForm.user_name = this.userData.user_name

      this.viewAdmission(this.admissionId)
    }
    else if (this.universityId && typeof this.universityId === "string") {
      this.editMode = true
      this.orgObj = { org_id: this.universityId }

      await this.viewUserProfile()
      this.organisationView()
      this.subscriptionModRuleAvailableCheck()
      this.studentData = this.userData
    }
    else {
      this.$router.back()
    }

    // All members who come to review screen should automatically get subscribed to this module id
    window.socket.emit("subscribe_user_to_module", this.userData.user_id, this.admissionId)
  },
  methods: {
    /**
     * subscriptionModRuleAvailableCheck
     */
    async subscriptionModRuleAvailableCheck () {
      try {
        const ruleAvailableResp = await Subscriptions.subscriptionModRuleAvailableCheck(this, { mod_name: this.modName })
        if (ruleAvailableResp.resp_status) {
          this.limitAvailabilityObj = ruleAvailableResp.resp_data.data
        }
      }
      catch (error) {
        console.error("subscriptionModRuleAvailableCheck Exception", error)
      }
    },
    /**
     * getAdmissionStateColorCode
     */
    getAdmissionStateColorCode (statecode) {
      return `vertical-align: middle; color: #FFF; background: ${config.AdmissionStateInfoColors[statecode]} !important;`
    },
    /**
     * courSelect
     */
    courSelect (event) {
      const findIndex = this.orgCourseList.findIndex(ele => ele.cour_name === event.target.value)
      if (findIndex >= 0) {
        try {
          this.orgCourAdmCycleList = JSON.parse(this.orgCourseList[findIndex].cour_adm_cycle)
        }
        catch (e) {
          console.error("courSelect: Missing Admission Cycle Timelines")
        }
      }
    },
    /**
     * getYear
     */
    getYear (courCycle) {
      var courCycleMonthNo = moment().month(courCycle).format("M")
      var currMonth = moment().month() + 1

      if (currMonth >= courCycleMonthNo) {
        // Next Year
        return moment().add(1, "years").year()
      }
      else {
        // Current Year
        return moment().year()
      }
    },
    /**
     * print/Download page
     */
    downloadPage () {
      var mywindow = window.open("", "PRINT")
      var html = "<HTML>\n<HEAD>\n"
      var headTags = document.getElementsByTagName("head")
      // console.log(headTags)
      if (headTags.length > 0) html += headTags[0].innerHTML

      html += "\n</HE" + "AD>\n<BODY>\n"
      var printReadyElem = document.getElementById("reviewAdmission")

      if (printReadyElem != null) html += printReadyElem.innerHTML
      else {
        alert("Error, no contents.")
        return
      }

      html += "\n</BO" + "DY>\n</HT" + "ML>"

      mywindow.document.write(html)
      // mywindow.document.close() // necessary for IE >= 10
      // mywindow.focus() // necessary for IE >= 10*/

      // mywindow.print()
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * organisationView
     */
    async organisationView () {
      const orgResp = await Organisations.organisationView(this, this.orgObj.org_id)
      if (orgResp.resp_status) {
        this.orgObj = orgResp.resp_data.data

        this.organisationCourseListAdm(this.orgObj.org_id)
        const imageResp = await Images.imageListProfileImages(this, this.orgObj.org_id)
        if (imageResp.resp_status) {
          this.orgObj = { ...this.orgObj, ...imageResp.resp_data }
        }
      }
      else {
        ApiResponse.responseMessageDisplay(this, orgResp)
      }
    },
    /**
     * showAddEducation
     */
    showAddEducation () {
      this.addEducationShow = true
      this.educationSubmited = false
      this.educationForm = {
        subjects: [],
        videoLinks: [],
        attachments: []
      }
    },
    /**
     * closeEducationModal
     */
    closeEducationModal () {
      this.addEducationShow = false
    },
    /*
     * addEducation
     */
    async addEducation (payload) {
      if (!this.user_profile.education) {
        this.user_profile.education = []
      }

      const educationIndex = this.user_profile.education.findIndex(edu => edu.id === payload.newEducation.id)
      if (educationIndex >= 0) {
        this.user_profile.education[educationIndex] = payload.newEducation
      }
      else {
        this.user_profile.education.unshift(payload.newEducation)
      }

      this.extraCurricularListObj[payload.newEducation.id] = payload.extraCurricularList
      this.viewUserProfile()
      this.addEducationShow = false
    },
    /**
     * showProgramModal
     */
    showProgramModal () {
      this.showProgramTestModal = true
      this.programEditObj = {}
    },
    /**
     * closeProgramModal
     */
    closeProgramModal (event) {
      if (event) {
        this.userProgramTest = JSON.parse(event.resp_data.user_program_test)
      }
      this.showProgramTestModal = false
    },
    /**
     * viewAdmission
    */
    async viewAdmission () {
      const admissionResp = await Admissions.admissionView(this, this.admissionId)
      if (admissionResp.resp_status) {
        this.admissionObj = admissionResp.resp_data.data

        if (this.admissionObj.adm_state_num < 2) {
          // adm_state_num 2 is STU_APP_DONE
          this.subscriptionModRuleAvailableCheck()
        }

        this.orgObj.org_id = this.admissionObj.university_id

        if (this.admissionObj && this.admissionObj.adm_state !== "STU_APP_START") { // To Show and hide
          this.disableUserInput = true
        }

        try {
          this.vmAdmissionForm = JSON.parse(admissionResp.resp_data.data.adm_fields)
          this.vmAdmissionForm.country_name = this.userData.country_name
          this.vmAdmissionForm.user_name = this.userData.user_name
        }
        catch (e) {
          console.error("Exception:viewAdmission(): ex", e)
        }

        this.studentData.user_id = admissionResp.resp_data.data.user_id
        this.studentData.user_name = admissionResp.resp_data.data.student_name
        this.studentData.user_email = admissionResp.resp_data.data.student_email
        this.studentData.user_mobile = admissionResp.resp_data.data.student_mobile
        this.studentData.user_gender = admissionResp.resp_data.data.student_gender
        this.studentData.user_address = admissionResp.resp_data.data.student_address
        this.studentData.user_parent_emails = admissionResp.resp_data.data.student_parent_emails

        // Get Organisation Data
        this.organisationView()

        if (this.admissionObj.spcm_id) {
          // If the user is a SPCM Student then set the admission_view as the counselor has already added it in admission
          this.$route.meta.name = "admission_view"
        }

        if (this.adminView || this.orgView || this.$route.meta.name === "admission_view") {
          this.selectedTab = this.cvTabObj.review
          this.viewApplicationRoute = true
          this.disableUserInput = true
          this.editMode = false
        }

        // User is a Student itself and application is UNLOCKED & in edit button pressed by user
        if (admissionResp.resp_data.data.user_id === this.userData.user_id &&
          !this.viewApplicationRoute &&
          !this.userProfileUnlockState[this.admissionObj.adm_state]
        ) {
          // If this is not an unlock state and still user try to edit
          this.viewApplicationRoute = true
          this.editMode = false
          this.selectedTab = this.cvTabObj.review
        }
        else if (this.admissionObj.user_id === this.userData.user_id) {
          this.editMode = true
        }

        // @todo: @shubham Please explain what is happening here
        // Get User profile from application saved in admission if this is view page
        if (this.admissionObj && this.admissionObj.adm_user_profile && this.viewApplicationRoute) {
          if (typeof this.admissionObj.adm_user_profile === "string") {
            this.admissionObj.adm_user_profile = JSON.parse(this.admissionObj.adm_user_profile)
          }
          else {
            this.admissionObj.adm_user_profile = this.admissionObj.adm_user_profile
          }

          this.extraCurricularListObj = this.admissionObj.adm_user_profile.user_exc_obj_list
          this.user_profile = this.admissionObj.adm_user_profile.user_profile

          if (this.admissionObj.adm_user_profile.user_program_test) {
            this.userProgramTest = this.admissionObj.adm_user_profile.user_program_test
          }

          if (this.admissionObj.adm_message && this.admissionObj.adm_message.includes(":")) {
            const messsageWithReasonCode = this.admissionObj.adm_message.split(" : ")
            this.admissionObj.adm_reason_code = messsageWithReasonCode[0]
            this.admissionObj.adm_message = messsageWithReasonCode[1]
          }
          else {
            this.admissionObj.adm_reason_code = null
            this.admissionObj.adm_message = null
          }
        }
        else {
          this.viewUserProfile()
        }
      }
      else {
        ApiResponse.responseMessageDisplay(this, admissionResp)
      }
    },
    /**
     * viewUserProfile
     */
    async viewUserProfile () {
      const userProfile = await User.userProfileView(this, this.userData.user_id)
      if (userProfile.resp_status) {
        this.extraCurricularListObj = userProfile.resp_data.data.extraCurricularListObj

        if (userProfile.resp_data.data.user_profile) {
          if (typeof userProfile.user_profile === "string") {
            this.user_profile = JSON.parse(userProfile.resp_data.data.user_profile)
          }
          else {
            this.user_profile = userProfile.resp_data.data.user_profile
          }
        }

        if (userProfile.resp_data.data.user_program_test) {
          this.userProgramTest = JSON.parse(userProfile.resp_data.data.user_program_test)
        }
      }
    },
    /**
     * addUpdateAdmission
     */
    async addUpdateAdmission (BUTTON_STATUS = null) {
      this.vmAdmissionForm.user_name = this.userData.user_name

      let payload = {
        adm_id: null,
        university_id: this.orgObj.org_id,
        adm_fields: JSON.stringify(this.vmAdmissionForm),
        adm_message: null,
        adm_state: null
      }

      if (this.admissionObj.spcm_id) {
        // Pass the spcm_id if present. It will be used by the backend to bypass payments
        payload.spcm_id = this.admissionObj.spcm_id
      }

      if (BUTTON_STATUS === "NEED_INFO" && !this.admissionObj.adm_reason_code) {
        // On Need More Info Reason is Mandatory
        this.showToast = true
        this.toastTitle = "Admission form review status"
        this.toastVariant = "danger"
        this.toastMsg = "Please choose a reason from the drop-down"
        return
      }

      let admissionResp

      if (this.admissionId) {
        // Edit Mode
        payload.adm_id = this.admissionId
        let displayDialogMessage = this.doubleConfirMessage

        if (this.admissionObj.adm_message && (this.orgView || this.adminView)) {
          // university or gide admin wants more info
          payload = {
            adm_id: this.admissionId,
            adm_message: this.admissionObj.adm_reason_code + " : " + this.admissionObj.adm_message,
            adm_state: "GADMIN_APP_NEED_INFO"
          }

          if (this.adminView &&
            this.admissionObj.adm_state_num >= config.AdmissionStateNum.GADMIN_APP_NEED_INFO &&
            this.admissionObj.adm_state_num <= config.AdmissionStateNum.GADMIN_STU_APP_RESUBMIT
          ) {
            payload.adm_state = "GADMIN_APP_NEED_INFO"
          }
          else if (this.orgView &&
            this.admissionObj.adm_state_num >= config.AdmissionStateNum.UNIV_APP_RECVD &&
            this.admissionObj.adm_state_num <= config.AdmissionStateNum.UNIV_STU_APP_RESUBMIT
          ) {
            payload.adm_state = "UNIV_APP_NEED_INFO"
          }
          else if (this.orgView &&
            this.admissionObj.adm_state_num >= config.AdmissionStateNum.UNIV_APP_ACCEPTED &&
            this.admissionObj.adm_state_num <= config.AdmissionStateNum.UNIV_APP_ACP_RESUBMIT
          ) {
            payload.adm_state = "UNIV_APP_ACP_NEED_INFO"
          }

          displayDialogMessage = this.doubleConfirMessageByAdmin
        }

        if (this.selectedTab === this.cvTabObj.review || this.admissionObj.adm_state === "STU_ADM_START") {
          if (this.admissionObj.adm_state !== "STU_ADM_START") {
            const options = {
              title: "Please Confirm",
              size: "md",
              buttonSize: "md",
              okVariant: "primary",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            }

            const value = await this.$bvModal.msgBoxConfirm(displayDialogMessage, options)
            if (!value) { return }
          }

          if (this.selectedTab === this.cvTabObj.review && this.admissionObj.adm_state === "STU_ADM_START") {
            payload.adm_state = "STU_APP_DONE"
          }

          if (this.selectedTab === this.cvTabObj.personalDetail || this.selectedTab === this.cvTabObj.program || this.selectedTab === this.cvTabObj.education) {
            this.goToNextTab()
            return
          }

          admissionResp = await Admissions.admissionEdit(this, payload)
          if (!admissionResp.resp_status) {
            if (admissionResp.resp_code === "ERR_SUBS_PLAN_PURCHASE_REQUIRED" ||
              admissionResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER"
            ) {
              let redirect = `admission/${this.admissionId}/edit`

              setTimeout(() => {
                // redirect after 3 sec
                this.$router.push(`/subscription_plans?module_name=admission&redirect=${redirect}`)
              }, 3000)
            }

            ApiResponse.responseMessageDisplay(this, admissionResp)
            return
          }
          else if (this.selectedTab === this.cvTabObj.review && this.admissionObj.adm_state === "STU_ADM_START") {
            this.admissionObj.adm_state = "STU_APP_DONE"
          }
        }
      }
      else {
        // Also need the cour_id
        for (let i in this.orgCourseList) {
          if (this.orgCourseList[i].cour_name == this.vmAdmissionForm.cour_name) {
            this.vmAdmissionForm.cour_id = this.orgCourseList[i].cour_id
            payload.cour_id = this.orgCourseList[i].cour_id
          }
        }
        payload.adm_fields = JSON.stringify(this.vmAdmissionForm)

        admissionResp = await Admissions.admissionAdd(this, payload)
        ApiResponse.responseMessageDisplay(this, admissionResp)
        if (!admissionResp.resp_status) {
          return
        }

        this.admissionObj.adm_state = "STU_ADM_START"
        this.admissionObj = admissionResp.resp_data
      }

      if (!admissionResp || (admissionResp && admissionResp.resp_status)) {
        this.goToNextTab()
        if (!this.admissionId) {
          this.admissionId = admissionResp.resp_data.adm_id
          this.$router.push(`/admission/${this.admissionId}/edit?state=${this.selectedTab}`)
        }
      }
    },
    /**
     * goToNextTab
     */
    goToNextTab () {
      switch (this.selectedTab) {
        case this.cvTabObj.admission:
          this.selectedTab = this.cvTabObj.personalDetail
          break
        case this.cvTabObj.personalDetail:
          this.selectedTab = this.cvTabObj.education
          break
        case this.cvTabObj.education:
          this.selectedTab = this.cvTabObj.program
          break
        case this.cvTabObj.program:
          this.selectedTab = this.cvTabObj.attachments
          break
        case this.cvTabObj.attachments:
          this.selectedTab = this.cvTabObj.review
          break
        case this.cvTabObj.review:
          this.$router.push("/admission_dashboard")
          break
        default:
          break
      }
    },
    /**
     * applicationUpdate
     */
    async applicationUpdate (BUTTON_STATUS = null) {
      const payload = {
        adm_id: this.admissionId,
        university_id: this.orgObj.org_id,
        // adm_fields: null, //Dont Send this. Keeping the variable commented for object sanctity
        adm_message: "",
        adm_state: ""
      }

      if (this.orgView) {
        payload.adm_state = (BUTTON_STATUS === "ACCEPT") ? "UNIV_APP_ACCEPTED" : (BUTTON_STATUS === "OFFER") ? "UNIV_APP_OFFER_SENT" : "UNIV_APP_REJECTED"
        payload.adm_message = this.admissionObj.adm_reason_code + " : " + this.admissionObj.adm_message
      }
      else if (this.adminView) {
        payload.adm_state = (BUTTON_STATUS === "REJECT") ? "GADMIN_APP_REJECTED" : "UNIV_APP_RECVD"
        payload.adm_message = this.admissionObj.adm_reason_code + " : " + this.admissionObj.adm_message
      }
      else {
        return
      }

      const admissionResp = await Admissions.admissionEdit(this, payload)
      ApiResponse.responseMessageDisplay(this, admissionResp)
      if (admissionResp.resp_status) {
        this.admissionObj.adm_state = payload.adm_state
        this.$router.push("/admission_dashboard")
      }
    },
    /**
     * closeAdmissionAddModal
     */
    closeAdmissionAddModal () {
      this.$emit("emitCloseAdmissionAddModal", false)
    },
    /**
     * organisationCourseListAdm
     */
    async organisationCourseListAdm (orgId) {
      const organisationCourseListAdmResp = await Organisations.organisationCourseListAdm(this, orgId)
      if (organisationCourseListAdmResp.resp_status) {
        this.orgDegreeList = organisationCourseListAdmResp.resp_data.org_degree_list.data
        this.orgCourseList = organisationCourseListAdmResp.resp_data.org_course_list.data
        if (this.vmAdmissionForm.cour_name) {
          this.courSelect({ target: { value: this.vmAdmissionForm.cour_name } })
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@media only screen and (max-width:776px) and (min-width:320px) {
  #reviewAdmission{
    .profile-header{
      .user-detail{
        height: 50px !important;
        margin-top: 50px !important;
      }
      .cover-container{
        height: auto;
        .details_flag{
          white-space: normal;
          width: auto;
        }
      }
    }
  }
}
.Documents{
  .document_hr_color{
    border-color: var(--iq-primary) !important;
  }
  .document_card{
    padding-left: 0px !important;
    box-shadow: none !important;
  }
}
</style>
